// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/helpers.ts"
);
import.meta.hot.lastModified = "1724962854547.6426";
}
// REMIX HMR END

import { json } from "@remix-run/node";

export const badRequest = <T>(data: T) => json(data, { status: 400 });

export const validateEmail = (email: unknown) => {
  const emailRegex = /^\S+@\S+\.\S+$/;

  if (typeof email !== "string" || !emailRegex.test(email)) {
    return "Incorrect email format.";
  }
};

export const validateUsername = (username: unknown) => {
  if (typeof username !== "string" || username.length < 3) {
    return "Usernames must be at least 3 characters long.";
  }
};

export const validatePassword = (password: unknown) => {
  if (typeof password !== "string" || password.length < 6) {
    return "Passwords must be at least 6 characters long.";
  }

  const hasCapitalLetter = /[A-Z]/.test(password);
  const hasNumber = /[0-9]/.test(password);

  if (!hasCapitalLetter) {
    return "Passwords must contain at least one capital letter.";
  }

  if (!hasNumber) {
    return "Passwords must contain at least one number.";
  }
};

export const validateLoginPassword = (password: unknown) => {
  if (typeof password !== "string" || password.length < 6) {
    return "Passwords must be at least 6 characters long.";
  }
};

export const validateConfirmPassword = (
  password: unknown,
  confirmPassword: unknown
) => {
  if (typeof confirmPassword !== "string" || confirmPassword.length < 6) {
    return "Passwords must be at least 6 characters long.";
  }

  if (password !== confirmPassword) {
    return "Passwords do not match.";
  }
};

export const parseTimestamp = (timestamp: string | null | undefined) => {
  if (!timestamp) return new Date();

  const hasT = timestamp.includes("T");
  const splitBy = hasT ? "T" : " ";

  const [date, time] = timestamp.split(splitBy);

  return new Date(`${date}T${time}.000Z`);
};
